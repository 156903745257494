import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { store } from './redux/store.js'
import { StepProvider } from './context/useStepContext.js'

import App from './App'

import './index.css'
import 'react-tippy/dist/tippy.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

if ('serviceWorker' in navigator) {
  // Check if service worker can be registered
  navigator.serviceWorker
    .register('/sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope)
    })
    .catch((error) => {
      console.log('Service Worker registration failed:', error)
    })
}

root.render(
  <Provider store={store}>
    <StepProvider>
      <App />
      <ToastContainer />
    </StepProvider>
  </Provider>,
)
