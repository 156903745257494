import React, { useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'

const PrivacyPolicy = () => {
  const [activeIndex, setActiveIndex] = useState(null)

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  //   const faqs = [
  //     {
  //       question: 'What personal data do we collect?',
  //       answerHtml: (
  //         <>
  //           <p>We collect the following personal information:</p>
  //           <ul className='list-disc list-inside'>
  //             <li>Full name, email, and phone number</li>
  //             <li>Billing and shipping addresses</li>
  //             <li>Payment details (secured & encrypted)</li>
  //             <li>IP address, browser type, and login activity</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //     {
  //       question: 'How do we use your data?',
  //       answerHtml: (
  //         <>
  //           <p>Your data is used for:</p>
  //           <ul className='list-disc list-inside'>
  //             <li>Processing transactions securely</li>
  //             <li>Providing customer support</li>
  //             <li>Sending updates and promotions</li>
  //             <li>Improving security and fraud prevention</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //     {
  //       question: 'Do we share your data with third parties?',
  //       answerHtml: (
  //         <>
  //           <p>We do not sell your personal data. However, we share it with:</p>
  //           <ul className='list-disc list-inside'>
  //             <li>Payment processors like PayPal and Stripe</li>
  //             <li>Shipping companies for order deliveries</li>
  //             <li>Marketing services (only with your consent)</li>
  //             <li>Legal authorities if required by law</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //     {
  //       question: 'How do we protect your information?',
  //       answerHtml: (
  //         <>
  //           <p>We implement security measures such as:</p>
  //           <ul className='list-disc list-inside'>
  //             <li>SSL encryption for secure data transfer</li>
  //             <li>Strict access control policies</li>
  //             <li>Regular security audits</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //     {
  //       question: 'How can you manage your privacy settings?',
  //       answerHtml: (
  //         <>
  //           <p>You can manage your privacy settings by:</p>
  //           <ul className='list-disc list-inside'>
  //             <li>Updating your account preferences</li>
  //             <li>Requesting data deletion</li>
  //             <li>Managing cookies through your browser settings</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //     {
  //       question: 'Do we use cookies?',
  //       answerHtml: (
  //         <>
  //           <p>Yes, we use cookies to enhance your experience by:</p>
  //           <ul className='list-disc list-inside'>
  //             <li>Storing login sessions securely</li>
  //             <li>Tracking website analytics</li>
  //             <li>Customizing content based on your preferences</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //     {
  //       question: 'How long do we retain your data?',
  //       answerHtml: (
  //         <>
  //           <p>We retain your data as long as:</p>
  //           <ul className='list-disc list-inside'>
  //             <li>Your account remains active</li>
  //             <li>It is required for legal compliance</li>
  //             <li>You have not requested its deletion</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //     {
  //       question: 'How can you contact us regarding privacy concerns?',
  //       answerHtml: (
  //         <>
  //           <p>You can reach us at:</p>
  //           <ul className='list-disc list-inside'>
  //             <li>Email: privacy@[yourcompany].com</li>
  //             <li>Phone: +1-234-567-890</li>
  //             <li>Office: 123 Privacy St, Tech City</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //     {
  //       question: 'What are your rights regarding your data?',
  //       answerHtml: (
  //         <>
  //           <p>You have the right to:</p>
  //           <ul className='list-disc list-inside'>
  //             <li>Request access to your data</li>
  //             <li>Ask for corrections to incorrect data</li>
  //             <li>Request deletion of your personal data</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //     {
  //       question: 'Is our privacy policy subject to change?',
  //       answerHtml: (
  //         <>
  //           <p>
  //             Yes, we may update our privacy policy periodically. Changes will be:
  //           </p>
  //           <ul className='list-disc list-inside'>
  //             <li>Published on our website</li>
  //             <li>Notified to users via email (if necessary)</li>
  //             <li>Effective from the updated date mentioned</li>
  //           </ul>
  //         </>
  //       ),
  //     },
  //   ]

  const faqs = [
    {
      question: 'What personal information do we collect?',
      answerHtml: (
        <>
          <p className='text-gray-700'>
            We collect personal details such as your name, contact information,
            and vehicle details when you register on our platform. Additionally,
            we may collect financial details and dealer information from sellers
            and buyers to facilitate transactions and provide our services.
          </p>
        </>
      ),
    },
    {
      question: 'How is my personal information used?',
      answerHtml: (
        <>
          <p className='text-gray-700'>
            Your personal information is used to create your account, verify
            your identity, process transactions, and offer a personalized
            experience. For sellers, this includes vehicle listings, financial
            details, and payment processing. For buyers, it’s used to facilitate
            bidding, auction participation, and payment processing.
          </p>
        </>
      ),
    },
    {
      question: 'Do we share your personal information with third parties?',
      answerHtml: (
        <>
          <p className='text-gray-700'>
            We do not sell or share your personal information with third parties
            for marketing purposes. However, we may share your information with
            trusted partners (e.g., payment processors) in order to facilitate
            transactions. These partners are obligated to maintain the
            confidentiality of your information.
          </p>
        </>
      ),
    },
    {
      question: 'How do we protect your personal information?',
      answerHtml: (
        <>
          <p className='text-gray-700'>
            We use industry-standard security measures to protect your personal
            information, including encryption and secure server protocols.
            Additionally, we implement strict access controls to ensure that
            only authorized personnel can access sensitive data.
          </p>
        </>
      ),
    },
    {
      question: 'How long do we retain your information?',
      answerHtml: (
        <>
          <p className='text-gray-700'>
            We retain your personal information only for as long as necessary to
            provide our services, process transactions, and comply with legal
            obligations. Once you delete your account, your information will be
            securely removed from our database. Any retained data will be
            anonymized if required for compliance or legal purposes.
          </p>
        </>
      ),
    },
    {
      question: 'Can I update or delete my personal information?',
      answerHtml: (
        <>
          <p className='text-gray-700'>
            Yes, you can update or delete your personal information at any time.
            To update your information, simply log into your account and make
            the necessary changes.
          </p>
        </>
      ),
    },
    {
      question: 'How do we handle payment information?',
      answerHtml: (
        <>
          <p className='text-gray-700'>
            We do not store your payment information directly. All payment
            transactions are processed securely through third-party payment
            providers like Stripe, which comply with the highest industry
            standards for payment security.
          </p>
        </>
      ),
    },
    {
      question: 'Do we use cookies on our website?',
      answerHtml: (
        <>
          <p className='text-gray-700'>
            Yes, we use cookies to improve user experience, analyze website
            traffic, and remember user preferences. You can manage your cookie
            preferences through your browser settings.
          </p>
        </>
      ),
    },
    {
      question: 'What should I do if I have concerns about my privacy?',
      answerHtml: (
        <>
          <p className='text-gray-700'>
            If you have any concerns or questions about how we handle your
            privacy, please contact our support team. We take privacy seriously
            and will address your concerns as quickly as possible.
          </p>
        </>
      ),
    },
  ]

  return (
    <div className='login-root'>
      <div className='w-full h-auto  p-0 m-0 overflow-y-auto lg:h-full'>
        <div className='flex flex-col items-center justify-center w-full p-5 py-8 m-0 bg-white lg:justify-center md:w-auto md:p-20'>
          <div className='bg-white text-gray-900'>
            <h1 className='text-4xl font-semibold text-center mb-6 text-primary'>
              Privacy Policy
            </h1>
            <div className='space-y-4 md:p-20'>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className='border border-light-gray rounded-lg overflow-hidden'
                >
                  <div
                    className='flex justify-between items-center p-4 cursor-pointer'
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className='text-lg font-medium'>{faq.question}</span>
                    <FiChevronDown
                      className={`transform transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : ''}`}
                    />
                  </div>

                  <div
                    className={`bg-light-gray overflow-hidden transition-all duration-500 ease-in-out ${
                      activeIndex === index
                        ? 'max-h-[500px] opacity-100 p-4'
                        : 'max-h-0 opacity-0'
                    }`}
                  >
                    <div className='text-sm' />
                    {faq.answerHtml}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
